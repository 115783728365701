import React from "react";
import shortid from "shortid";
// Add Icons
import adIcon1 from "../Icons/ad_media0.png";
import adIcon2 from "../Icons/ad_media1.png";
import adIcon3 from "../Icons/ad_media2.png";
import adIcon4 from "../Icons/ad_media3.png";
import { motion } from "framer-motion";

import groupIcons from "../Icons/groupHorizontalIcons.svg";

const arrayAdIcons = [
  {
    photo: adIcon1,
    text: "First ad Image",
  },
  {
    photo: adIcon2,
    text: "Second ad Image",
  },
  {
    photo: adIcon3,
    text: "Third ad Image",
  },
  {
    photo: adIcon4,
    text: "Fourth ad Image",
  },
];

const Otext = ({ text1, text2, text3, link }) => {
  return (
    <>
      <p className="text-gray text-[16px] md:leading-[25px]  leading-[18px]  md:text-[20px]  md:pb-[20px] md:w-[80%] 2xl:w-[95%]">
        {text1}
      </p>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="block text-yellowDark text-[18px] md:text-[20px]  mb-[40px] md:mb-[70px] mt-[10px] md:mt-0 font-medium linktextAnimation cursor-pointer"
      >
        {text2}
        <span className="text-[#171717] ptextAnimation cursor-pointer font-medium">
          {" "}
          {text3}
        </span>
      </a>
    </>
  );
};

const adStyling = (e) => {
  if (e === adIcon1) {
    return "2xl:h-[70px] md:h-[65px] 2xl:h-[65px] h-[55px] md:h-[65px] md:w-auto 2xl:w-max mb-4 md:ml-4 2xl:ml-12 2xl:mr-2 mr-6 md:mr-8 grayscale";
  } else if (e === adIcon2) {
    return "md:h-[75px] 2xl:h-[70px] h-[60px] md:w-auto 2xl:w-max mb-4 2xl:mx-10 md:mx-4 md:mt-0 grayscale";
  } else if (e === adIcon3) {
    return "md:h-[70px] 2xl:h-[70px] h-[45px] pl-4 md:pl-0 md:w-auto 2xl:w-max mr-8 md:mr-0 md:ml-2 md:mr-4 2xl:mx-10 md:mt-0 mr-2 grayscale md:mb-4";
  } else {
    return "md:h-[55px] 2xl:h-[70px] h-[35px] md:w-auto 2xl:w-max 2xl:mx-10 md:mt-0 grayscale md:mb-4 ";
  }
};

const Section2 = () => {
  return (
    <>
      <div
        className="md:h-full mb-[55px] md:mb-[165px] justify-center flex-col section2 w-full"
        id="section2"
      >
        <div className=" md:pl-[6.95rem] pl-4 md:pr-0  pr-0 md:h-24 md:mt-24 mt-0 flex items-center">
          <div className="h-max  md:h-1/2 md:w-full flex md:items-center md:flex-row flex-col mt-[80px] md:mt-0  w-full">
            <motion.h1
              initial={{
                opacity: 0,
                translateY: "2rem",
              }}
              whileInView={{
                translateY: "0rem",
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              animate="visible"
              viewport={{ once: true, amount: 0.5 }}
              className="mb-[30px] md:mb-0 text-l  lg:text-[35px] text-[25px] font-extrabold flex 2xl:pr-32"
            >
              Apariții <span className="text-yellowDark">media</span>:
            </motion.h1>
            <div className="flex flex-wrap 2xl:flex-nowrap flex-row md:w-full items-center md:pt-4 2xl:pt-0 pt-[10px] md:justify-end 2xl:mr-0 md:mr-[100px] lg:px-19 2xl:pr-52 mt-0 md:mt-2">
              {arrayAdIcons.map((e, i) => (
                <motion.img
                  initial={{
                    opacity: 0,
                    translateY: "2rem",
                  }}
                  whileInView={{
                    translateY: "0rem",
                    opacity: 1,
                    transition: {
                      delay: (i + 0.3) * 0.2,
                      duration: 0.3,
                    },
                  }}
                  animate="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  className={`${adStyling(e.photo)}`}
                  src={e.photo}
                  alt="e.text"
                  key={i}
                  // ``
                />
              ))}
            </div>
          </div>
        </div>
        <div className=" lg:pl-[6.95rem] md:pl-[6.95rem] pl-5 flex md:flex-row flex-col items-start md:mt-[70px] mt-0 pr-0 md:pr-16 2xl:pr-96">
          <motion.div
            initial={{
              opacity: 0,
              translateY: "2rem",
            }}
            whileInView={{
              translateY: "0rem",
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.4,
              },
            }}
            animate="visible"
            viewport={{ once: true, amount: 0.5 }}
            className="flex-1  mr-0 h-full flex flex-col md:flex-col md:mr-[12.5rem] 2xl:mr-[9.438rem]"
          >
            <Otext
              text1={[
                "A7TV - Servify, Manager De Service. Business la superlativ cu flori stoian",
                <br />,
                "- Dragoș stan",
              ]}
              text2="Vizionează"
              text3="clipul"
              link="https://www.youtube.com/watch?v=2K2AZIYLtYs"
              key={shortid.generate()}
            />
            <Otext
              text1="Forbes - Un game changer pentru service-urile auto și antreprenori"
              text2="Citește "
              text3="articolul"
              link="https://www.forbes.ro/un-game-changer-pentru-service-urile-auto-si-antreprenori-176915"
              key={shortid.generate()}
            />
            <motion.div
              initial={{
                opacity: 0,
                translateY: "2rem",
              }}
              whileInView={{
                translateY: "0rem",
                opacity: 1,
                transition: {
                  delay: 0.4,
                  duration: 0.4,
                },
              }}
              animate="visible"
              viewport={{ once: true, amount: 0.5 }}
              className="md:w-full w-[90%] h-[100%] order-first md:order-last mt-[45px] md:mt-0 mb-[49px] md:mb-0"
            >
              <img
                src={groupIcons}
                alt="groupIcons"
                className="hover:rotate-2 transition duration-150 hover:duration-150"
              />
            </motion.div>
          </motion.div>
          <motion.div
            initial={{
              opacity: 0,
              translateY: "2rem",
            }}
            whileInView={{
              translateY: "0rem",
              opacity: 1,
              transition: {
                delay: 0.5,
                duration: 0.5,
              },
            }}
            animate="visible"
            viewport={{ once: true, amount: 0.5 }}
            className="flex-1"
          >
            <Otext
              className="pointer"
              text1="A7TV - Dragoș Stan, Antreprenor de succes știrea cea bună cu Cornel Dărvășan"
              text2="Vizionează "
              text3="clipul"
              link="https://www.youtube.com/watch?v=MUdPiBp-mro"
              key={shortid.generate()}
            />
            <Otext
              text1="Ziarul Financiar - Dragoş Stan, Fondator şi ceo servify"
              text2="Citește "
              text3="articolul"
              link="https://www.zf.ro/zf-it-generation/zf-it-generation-dragos-stan-fondator-ceo-servify-aplicatie-saas-19659910"
              key={shortid.generate()}
            />
            <Otext
              text1="Startup Ro- Tânărul de 26 de ani din arad care vrea să revoluționeze service-urile auto cu aplicația sa"
              text2="Citește "
              text3="articolul"
              link="https://start-up.ro/tanarul-de-26-de-ani-din-arad-care-vrea-sa-revolutioneze-service-urile-auto/"
              key={shortid.generate()}
            />
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Section2;
