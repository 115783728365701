import React from "react";

import heartIcon from "../Icons/heart.svg";

const footer = () => {
  return (
    <>
      <div className="md:pl-64 flex xl:flex-row flex-col md:mt-20 mt-[42.5px] md:mb-14 mb-[30px] items-center">
        <div className=" md:mr-20">
          <h2 className="font-medium text-center md:text-left text-[18px] md:text-[20px]  md:mb-0 mb-[25px] md:leading-[28px]">
            Abonează-te la <br /> newsletterul nostru
          </h2>
        </div>
        <div className="flex-1 md:flex-row flex flex-col items-center justify-center w-full relative md:w-max md:mr-16">
          <input type="text" className="footerTextbox md:mb-0 mb-[20px]" />
          <button className="footerButton customBtnForm  md:mb-0 mb-[55px]">
            Abonează-te
          </button>
        </div>
        <div className="flex-1 mx-0 md:ml-10 w-full flex 2xl:block items-center justify-center">
          <ul className="flex">
            <li className="md:mr-10 mr-[28px] text-[16px] text-[#727272] hover:text-black cursor-pointer transition duration-300 ease-in-out">
              <a href="https://serviceoptimal.ro/">OPTIMAL Auto</a>
            </li>
            <li className="md:mr-10 mr-[28px] text-[16px] text-[#727272] hover:text-black cursor-pointer transition duration-300 ease-in-out">
              <a href=" https://optimalgroup.ro/">OPTIMAL Group</a>
            </li>
            <li className="text-[16px] text-[#727272] hover:text-black cursor-pointer transition duration-300 ease-in-out">
              <a href="https://servify.ro/">ServiFy</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-[#F5F5F5] flex items-center justify-center">
        <p className="text-[#959595] py-5 text-[14px] w-7/12 md:w-full text-center">
          Designed & developed by <br /> Pixarray Nomads with
          <img
            className="md:ml-2 ml-[3px] md:mb-0 mb-[2px] inline-block cursor-pointer fill-[#959595] animate-pulse"
            src={heartIcon}
            alt="heartIcon"
          />
        </p>
      </div>
    </>
  );
};

export default footer;
