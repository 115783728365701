import React, { useRef } from "react";
import skateIcon from "../Icons/man_skateboard.svg";
import parachuteBook from "../Icons/books_parachute.svg";
import sendIcon from "../Icons/sendIcon.svg";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
// Popup
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useForm } from "react-hook-form";

const Section5 = () => {
  // Form Validation

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    node: "onChange",
    defaultValues: {
      full_Name: "",
      city_Name: "",
      service_Name: "",
      telephone_Number: "",
      email: "",
    },
  });

  console.log(errors);
  const onSubmit = (data) => console.log(data);

  // Email sending
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
    // if (isValid) {
    //   emailjs
    //     .sendForm(
    //       "gmail",
    //       "template_pj7jqn7",
    //       form.current,
    //       "2n9PX_xbS_Fh2yfCv"
    //     )
    //     .then(
    //       (result) => {
    //         console.log(result.text);
    //       },
    //       (error) => {
    //         console.log(error.text);
    //       }
    //     );
    // }
  };

  return (
    <div
      className=" md:pl-20 pl-5 md:mt-40 mt-0 md:pb-36 pb-0 bg-beige"
      id="section5"
    >
      <div className="flex w-full md:flex-row flex-col">
        <div className="md:order-first order-last flex-1 mb:mt-32 mt-0 ">
          <motion.h1
            initial={{
              opacity: 0,
              translateY: "2rem",
            }}
            whileInView={{
              translateY: "0rem",
              opacity: 1,
              transition: {
                duration: 0.4,
              },
            }}
            animate="visible"
            viewport={{ once: true, amount: 0.5 }}
            className="font-extrabold text-[25px] md:text-[35px] pb-[35px] mt-[55px] md:mt-32 leading-[30px] md:leading-[45px] "
          >
            Lasă-mi un mesaj și revin <br />
            în cel mai scurt timp posibil!
          </motion.h1>
        </div>
        <motion.div
          initial={{
            opacity: 0,
            translateY: "2rem",
          }}
          whileInView={{
            translateY: "0rem",
            opacity: 1,
            transition: {
              delay: 0.4,
              duration: 0.4,
            },
          }}
          animate="visible"
          viewport={{ once: true, amount: 0.5 }}
          className="flex-1"
        >
          <img
            src={skateIcon}
            alt="skateIcon"
            className="-mt-[100px] md:-mt-[11.5rem] px-3 md:px-10 2xl:px-0 md:animate-[bounce_2s_ease-out_infinite]"
          />
        </motion.div>
      </div>

      <div className="flex md:mt-24 mt-0 w-full md:w-max ">
        <motion.div
          initial={{
            opacity: 0,
            translateY: "2rem",
          }}
          whileInView={{
            translateY: "0rem",
            opacity: 1,
            transition: {
              delay: 0.5,
              duration: 0.5,
            },
          }}
          animate="visible"
          viewport={{ once: true, amount: 0.5 }}
          className="2xl:mr-[9.625rem] md:mr-[5.625rem] mr-0 w-max absolute md:relative"
        >
          <img
            className="translation duration-1000 hover:translate-y-8 w-[12.5rem] invisible md:visible"
            src={parachuteBook}
            alt="parachuteBook"
          />
        </motion.div>{" "}
        <form
          className="flex lg:flex-row flex-col w-full"
          ref={form}
          onSubmit={sendEmail}
        >
          <div className="flex-1 flex flex-col w-full md:w-max items-center md:items-baseline sm:block">
            <motion.label
              initial={{
                opacity: 0,
                translateY: "2rem",
              }}
              whileInView={{
                translateY: "0rem",
                opacity: 1,
                transition: {
                  delay: 0.5,
                  duration: 0.4,
                },
              }}
              animate="visible"
              viewport={{ once: true, amount: 0.5 }}
              className="customLabel md:mb-11 mb-[25px] md:mr-10"
            >
              <label className="text-[16px]  md:text-[18px] ml-3 font-medium">
                Nume Complet
              </label>
              <input
                name="full_Name"
                type="text"
                className={`customInput border-2 ${
                  errors.full_Name?.type
                    ? "border-2 border-solid border-red-600"
                    : "border-2 border-solid border-yellowDark"
                }`}
                {...register("full_Name", { required: true })}
              />
              <p id="error_message">
                {" "}
                {errors.full_Name?.type === "required" &&
                  "Numele complet este necesar"}
              </p>
            </motion.label>
            <motion.label
              initial={{
                opacity: 0,
                translateY: "2rem",
              }}
              whileInView={{
                translateY: "0rem",
                opacity: 1,
                transition: {
                  delay: 0.5,
                  duration: 0.5,
                },
              }}
              animate="visible"
              viewport={{ once: true, amount: 0.5 }}
              className="customLabel md:mb-11 mb-[25px]  mb:mr-10 mr-0"
            >
              <label className="text-[16px]  md:text-[18px] ml-3 font-medium">
                Oraș
              </label>
              <input
                name="city_Name"
                type="text"
                className={`customInput border-2 ${
                  errors.city_Name?.type
                    ? "border-2 border-solid border-red-600"
                    : "border-2 border-solid border-yellowDark"
                }`}
                {...register("city_Name", { required: true })}
              />

              <p id="error_message">
                {" "}
                {errors.city_Name?.type === "required" &&
                  "Numele orașului este obligatoriu"}
              </p>
            </motion.label>
            <motion.label
              initial={{
                opacity: 0,
                translateY: "2rem",
              }}
              whileInView={{
                translateY: "0rem",
                opacity: 1,
                transition: {
                  delay: 0.5,
                  duration: 0.4,
                },
              }}
              animate="visible"
              viewport={{ once: true, amount: 0.5 }}
              className="customLabel mb-[25px] md:mb-0 md:mr-0 2xl:mr-10"
            >
              <label className="text-[16px]  md:text-[18px] ml-3 font-medium">
                Nume Service
              </label>
              <input
                name="service_Name"
                type="text"
                className={`customInput border-2 ${
                  errors.service_Name?.type
                    ? "border-2 border-solid border-red-600"
                    : "border-2 border-solid border-yellowDark"
                }`}
                {...register("service_Name", { required: true })}
              />

              <p id="error_message">
                {" "}
                {errors.service_Name?.type === "required" &&
                  "Numele serviciului este necesar"}
              </p>
            </motion.label>
          </div>
          <div className="flex-1 relative z-10 md:mt-10 mt-0 lg:mt-0 flex items-start flex-col sm:block">
            <motion.label
              initial={{
                opacity: 0,
                translateY: "2rem",
              }}
              whileInView={{
                translateY: "0rem",
                opacity: 1,
                transition: {
                  delay: 0.4,
                  duration: 0.4,
                },
              }}
              animate="visible"
              viewport={{ once: true, amount: 0.5 }}
              className="customLabel md:mb-11 mb-[25px] "
            >
              <label className="w-max text-[16px]  md:text-[18px] ml-3 font-medium">
                Nr. telefon
              </label>
              <input
                name="telephone_Number"
                className={`customInput border-2 ${
                  errors.telephone_Number?.message
                    ? "border-2 border-solid border-red-600"
                    : "border-2 border-solid border-yellowDark"
                }`}
                type="text"
                {...register("telephone_Number", {
                  required: "Numărul de telefon este obligatoriu",
                })}
              />

              <p id="error_message">
                {" "}
                {errors.telephone_Number && (
                  <span role="alert">{errors.telephone_Number.message}</span>
                )}
              </p>
            </motion.label>
            <motion.label
              initial={{
                opacity: 0,
                translateY: "2rem",
              }}
              whileInView={{
                translateY: "0rem",
                opacity: 1,
                transition: {
                  delay: 0.5,
                  duration: 0.4,
                },
              }}
              animate="visible"
              viewport={{ once: true, amount: 0.5 }}
              className="customLabel md:mb-11 mb-[30px] w-max"
            >
              <label className="text-[16px] md:text-[18px] ml-3 font-medium">
                Mailul tău
              </label>

              <input
                name="email_Address"
                className={`customInput border-2 ${
                  errors.email?.message
                    ? "border-2 border-solid border-red-600"
                    : "border-2 border-solid border-yellowDark"
                }`}
                type="text"
                {...register("email", {
                  required: "Este necesară adresa de e-mail",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Acesta nu este un format de e-mail valid",
                  },
                })}
              />

              <p id="error_message">
                {" "}
                {errors.email && (
                  <span role="alert">{errors.email.message}</span>
                )}
              </p>
            </motion.label>
            <motion.div
              initial={{
                opacity: 0,
                translateY: "2rem",
              }}
              whileInView={{
                translateY: "0rem",
                opacity: 1,
                transition: {
                  delay: 0.6,
                  duration: 0.4,
                },
              }}
              animate="visible"
              viewport={{ once: true, amount: 0.5 }}
              className="lg:absolute bottom-0 right-0"
            >
              <Popup
                className="w-[250px]"
                // disabled={`${
                //   Object.keys(formErrors).length === 0 && isSubmit
                //     ? true
                //     : false
                // }`}]
                disabled={!isValid}
                trigger={
                  <button
                    onClick={sendEmail}
                    type="submit"
                    className="button mb-[50px] md:mb-0 md:mr-12 py-[16px] md:py-1 px-[18px] md:px-[1.7rem] w-[130px] md:w-[11.938rem] max-h-[50px] md:max-h-[3.75rem] flex items-center justify-between customBtn customBtnForm"
                  >
                    Trimite
                    <img
                      className="md:h-[30px] h-[20px] w-[20px] md:w-[30px] md:m-0 ml-[16.4px]"
                      src={sendIcon}
                      alt="sendIcon"
                    />
                  </button>
                }
                modal
              >
                {" "}
                <div className="w-full h-full">
                  {" "}
                  <img src="" alt="" />
                  <h3 className="w-5/12">
                    <span className="text-[15px] leading-[20px] color-[#727272]">
                      Mailul a fost trimis cu succes!
                    </span>
                    <span className="text-[15px] leading-[20px] color-[#727272]">
                      Mulțumim pentru solicitare!
                    </span>
                  </h3>
                </div>
                {/* {Object.keys(formErrors).length === 0 && isSubmit ? (
                  <span> Email Successfully Sent! </span>
                ) : (
                  <span>Hey</span>
                )} */}
              </Popup>
            </motion.div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Section5;
