import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import thumbnail from "../Icons/thumbnail.png";
import Modal from "./modal/Modal";
import "../index.css";

const Section1 = () => {
  const [isOpen, setIsOpen] = useState(false);

  const bottomUp = {
    offscreen: {
      opacity: 0,
      translateY: "5rem",
    },
    onscreen: {
      translateY: "0rem",
      opacity: 1,
      transition: {
        duration: 0.7,
      },
    },
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "15px";
    }
    return () => {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = "0px";
    };
  }, [isOpen]);

  return (
    <>
      <div
        className="h-full md:h-[100vh] 2xl:h-[88vh] bg-beige flex relative flex-col-reverse md:flex-row"
        id="section1"
      >
        {/* Left */}
        <div className="w-full md:w-5/12 pl-0 md:pl-[100px]  relative">
          {/* Thumbnail */}
          <div className=" thumbnail h-max md:h-full  items-start md:items-center md:flex-row flex-col flex">
            <motion.div
              initial={{
                opacity: 0,
                translateY: "2rem",
              }}
              whileInView={{
                translateY: "0rem",
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              animate="visible"
              viewport={{ once: true, amount: 0.5 }}
              className="px-5 md:px-0 mt-4 md:mt-0 relative halfBg"
            >
              <img
                className="md:h-[29rem] md:w-[29rem] w-[100rem] top-50% md:block"
                src={thumbnail}
                alt="thumbnail"
              />
              <button
                onClick={() => setIsOpen(true)}
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[16.6%] md:w-max md:h-max h-[18.2%]"
              >
                <i className="fa-solid fa-play fa-4x h-full w-full"></i>
              </button>
            </motion.div>
          </div>
        </div>
        {/* Right */}
        <div className="md:w-8/12 w-12/12 md:ml-[81px]">
          <div className="pl-5 md:pl-0 flex textContainer w-full h-full justify-center flex-col">
            <motion.h2
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.5 }}
              animate="visible"
              variants={bottomUp}
              className="pb-[18px] text-[25px] md:text-[35px]  mt-[40px] md:mt-0  font-extrabold"
            >
              Sunt Dragos Stan,
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, translateY: "5rem" }}
              whileInView={{
                translateY: "0rem",
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.7,
                },
              }}
              viewport={{ once: true, amount: 0.5 }}
              animate="visible"
              className="leading-[1.25] pr-5 md:pr-0 w-full 2xl:w-[67%] md:w-[85%] pb-[50px] md:pb-0 text-[16px] md:text-[20px]  text-gray"
            >
              <span className="pb-[15px]  inline-block">
                Antreprenor în serie, Fondator al OPTIMAL auto service, cel mai
                mare service specializat BMW din România și fondator al ServiFy,
                aplicație care te ajută să câștigi timp și să crești profitul
                service-ului tău.
              </span>
              <span className="inline-block">
                {" "}
                Prin ambiție, dedicare, multă muncă și training continuu am
                reușit să cresc OPTIMAL auto service de la 0 la peste 1 milion
                de euro cifră de afaceri și clienți din toată țara. Am fost
                implicat în ultimele 6 luni în creșterea și deschiderea a 5
                service-uri noi care au început să aibă rezultate după prima
                lună.
              </span>
            </motion.p>
          </div>
        </div>
        {/* <button className="primaryBtn" onClick={() => setIsOpen(true)}>
          Open Modal
        </button> */}
        {isOpen && <Modal setIsOpen={setIsOpen} />}
      </div>
    </>
  );
};

export default Section1;
