import React from "react";
import { Link } from "react-scroll";
import { motion, Variants } from "framer-motion";
import navbarIcon from "../Icons/menuIcon.svg";
import closeIcon from "../Icons/closeIcon.svg";
import logo2 from "../Icons/logo2.svg";

const Navbar = (value) => {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  const bottomUp: Variants = {
    offscreen: {
      opacity: 0,
      translateY: "5rem",
    },
    onscreen: {
      translateY: "0rem",
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <>
      <div className="md:pt-14 pl-0 md:pl-[10.5rem] pt-0 bg-[#171717] relative">
        <nav
          className={`${
            navbarOpen
              ? "navbar flex flex-wrap bg-[#f7c945] h-[67vh] absolute top-0 left-0 z-[1000] rounded-b-[25px] w-[100vw]"
              : "flex flex-wrap items-center justify-between bg-dark absolute top-0 left-0 z-[1000] w-full pl-0 md:pl-[100px] lg:pl-[50px] xl:pl-[100px]"
          }`}
        >
          {navbarOpen ? (
            <img
              className="absolute bottom-[3rem] left-[-4rem] h-[60%] w-[250px]"
              src={logo2}
              alt="logo2"
            />
          ) : (
            ""
          )}
          <div
            className={`${
              navbarOpen
                ? "navbar_bot w-max h-max pb-4 relative flex justify-between lg:w-auto lg:justify-start items-start z-10 pt-7"
                : "w-full relative flex justify-between lg:w-auto lg:justify-start items-center sm:w-full pt-[21px]"
            }`}
          >
            <motion.a
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.5 }}
              animate="visible"
              variants={bottomUp}
              className={`${
                navbarOpen
                  ? "font-bold mr-4 pl-5 md:pl-0 md:pt-0 w-8/12 md:w-full uppercase text-white hover:text-yellow text-[16px] md:text-[25px] transition duration-500"
                  : "font-bold md:mr-2 2xl:mr-6 pl-5 md:pl-0 md:pt-0 w-8/12 sm:w-5/12 md:w-full lg:leading-none text-white hover:text-yellow text-[16px] md:text-[21px] 2lx:text-[25px] transition duration-500"
              }`}
              href="/"
            >
              Antreprenor / Investitor / Business Mentor
            </motion.a>
            <button
              className={`${
                navbarOpen
                  ? "text-white visible cursor-pointer text-xl  leading-none px-1 pt-0 pb-1 mr-2 md:mr-0  border-transparent  bg-transparent block lg:hidden outline-none focus:outline-none"
                  : "text-white visible cursor-pointer text-xl  leading-none px-3 pt-0 pb-1 mr-2 md:mr-0  border-transparent  bg-transparent block lg:hidden outline-none focus:outline-none"
              }`}
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <img
                src={`${navbarOpen ? closeIcon : navbarIcon}`}
                alt="navbarIcon"
              />
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center md:pb-[1.2rem]" +
              (navbarOpen ? " flex justify-end h-full" : " hidden")
            }
          >
            <ul
              className={`${
                navbarOpen
                  ? "navUl flex flex-col lg:flex-row list-none lg:ml-auto pr-[60px] mb-32 mr-3"
                  : "navUl flex flex-col lg:flex-row list-none items-center lg:ml-auto lg:pr-[95px] 2xl:pr-[264px] mt-[60px] "
              }`}
            >
              <motion.li
                initial={{
                  opacity: 0,
                  translateY: "2rem",
                }}
                whileInView={{
                  translateY: "0rem",
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                  },
                }}
                animate="visible"
                viewport={{ once: true, amount: 0.5 }}
                className="nav-item"
              >
                <Link
                  className={`${
                    navbarOpen
                      ? "relative pb-1.5 mx-0 flex items-center text-xs font-bold text-white cursor-pointer mb-[65px]"
                      : "relative pb-1.5 mx-[30px] flex items-center text-xs font-bold text-white cursor-pointer"
                  }`}
                  activeClass="active"
                  to="section1"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  <motion.p
                    className={`${
                      navbarOpen
                        ? "font-semibold textTransition w-auto text-[20px] md:text-[18px] text-black pb-[8px]"
                        : "font-semibold textTransition w-auto text-[20px] md:text-[18px]"
                    }`}
                  >
                    Despre
                  </motion.p>
                </Link>
              </motion.li>
              <motion.li
                initial={{
                  opacity: 0,
                  translateY: "2rem",
                }}
                whileInView={{
                  translateY: "0rem",
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                    delay: 0.3,
                  },
                }}
                animate="visible"
                viewport={{ once: true, amount: 0.5 }}
                className="nav-item"
              >
                <Link
                  className={`${
                    navbarOpen
                      ? "relative pb-1.5 mx-0 flex items-center text-xs font-bold text-white cursor-pointer mb-[65px]"
                      : "relative pb-1.5 mx-[30px] flex items-center text-xs font-bold text-white cursor-pointer"
                  }`}
                  activeClass="active"
                  to="section4"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  <motion.p
                    className={`${
                      navbarOpen
                        ? "font-semibold textTransition w-auto text-[20px] md:text-[18px]  text-black pb-[8px]"
                        : "font-semibold textTransition w-auto text-[20px] md:text-[18px] "
                    }`}
                  >
                    Ce oferim
                  </motion.p>
                </Link>
              </motion.li>
              <motion.li
                initial={{
                  opacity: 0,
                  translateY: "2rem",
                }}
                whileInView={{
                  translateY: "0rem",
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                    delay: 0.5,
                  },
                }}
                animate="visible"
                viewport={{ once: true, amount: 0.5 }}
                className="nav-item"
              >
                <Link
                  className={`${
                    navbarOpen
                      ? "relative pb-1.5 mx-0 flex items-center text-xs font-bold text-white cursor-pointer mb-[40px]"
                      : "relative pb-1.5 ml-[30px] flex items-center text-xs font-bold text-white cursor-pointer"
                  }`}
                  activeClass="active"
                  to="section5"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  <motion.p
                    className={`${
                      navbarOpen
                        ? "font-semibold textTransition w-auto text-[20px] md:text-[18px]  text-black pb-[8px]"
                        : "font-semibold textTransition w-auto text-[20px] md:text-[18px]"
                    }`}
                  >
                    Contact
                  </motion.p>
                </Link>
              </motion.li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};
export default Navbar;
