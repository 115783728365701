import React from "react";
import "../index.css";
import ProfilePicture from "../Icons/profile_pic1.png";
import { motion } from "framer-motion";
import Logo from "../Icons/logo.svg";
import { Link } from "react-scroll";

const landingPage = () => {
  return (
    <div className="flex flex-col bg-[#171717] px-0 md:px-14 lg:pl-[50px] md:pl-[85px] lg:pr-[5px] relative pt-0 md:pt-[7%] md:pb-[8%]  xl:pl-[100px]">
      {/* Main */}
      <div className="flex h-full flex-col md:flex-row mt-[130px] md:mt-[0] bg-[#171717] relative">
        {/* Left */}
        <motion.div
          initial={{
            opacity: 0,
            translateY: "2rem",
          }}
          whileInView={{
            translateY: "0rem",
            opacity: 1,
            transition: {
              delay: 0.5,
              duration: 0.5,
            },
          }}
          animate="visible"
          viewport={{ once: true, amount: 0.5 }}
          className=" md:pl-0 pl-[20px] lg:mt-[200px] mt-0 2xl:mt-[15rem] md:pr-[20px] lg:pr-[0] flex-1 order-last md:order-first h-full flex-2 flex flex-col justify-end w-full z-10 md:w-[150%]  2xl:w-full"
        >
          <h1 className="font-extrabold  pb-[20px] md:pb-[66px] lg:w-[110%] w-full xl:w-full  md:leading-[65px] leading-[37px] text-[27px] md:text-[55px] text-yellow tracking-normal">
            Sesiune ONE to ONE <br />
            dedicată proprietarilor <br />
            și managerilor de service.
          </h1>

          <p className="font-medium lg:pb-[94px] pb-[45px] md:text-[27px] md:leading-[36px] leading-[24px] text-[18px] md:pr-0 text-white max-w-[42rem]">
            Program de creștere accelerată și eficientă a afacerilor din{" "}
            <br className="mb:visible hidden" />
            domeniul de servisare.
          </p>

          <button className="mb-[52px] md:mb-20 customBtn customBtnYellow smallTextButton">
            <Link
              smooth={true}
              to="section5"
              className="md:text-[22px] text-[16px]"
            >
              Programează o consultanță gratuită
            </Link>
          </button>
        </motion.div>
        <img
          className="absolute md:-top-[100px] md:left-[20%]  md:w-5/12 top-0 left-[20px] w-9/12  "
          src={Logo}
          alt="Logo"
        />
        {/* Right */}
        <motion.div
          initial={{
            opacity: 0,
            translateY: "2rem",
          }}
          whileInView={{
            translateY: "1rem",
            opacity: 1,
            transition: {
              delay: 0.7,
              duration: 0.5,
            },
          }}
          animate="visible"
          viewport={{ once: true, amount: 0.5 }}
          className="h-full flex md:w-max items-center pb-[1.75rem] justify-end mb-[1.5rem] md:mb-0"
        >
          <img
            src={ProfilePicture}
            alt="profilePic"
            className="md:w-[586px] lg:w-[500px] xl:w-[640px] 2xl:w-[640px] h-[264px] md:h-[100%] w-[261px] lg:pb-[180px]"
          />
        </motion.div>
      </div>
    </div>
  );
};

export default landingPage;
