import React from "react";
import circleIcon from "../Icons/circle_hands.svg";
import verticalIcon from "../Icons/vertical_hands.svg";
import { motion } from "framer-motion";
import { Link } from "react-scroll";

const Section4 = () => {
  return (
    <div className="" id="section4">
      <div className="pt-0 md:pt-[9rem] pl-0 md:pl-20  flex items-start md:items-center justify-center md:flex-row flex-col border-t-[2px]  border-[#D1914C]">
        <div className="pl-6 md:pl-0 w-full mt-[50px] md:mt-0 mb-[20px] md:mb-0 flex flex-col md:w-max md:flex-1 mr-0 md:mr-32">
          <motion.h1
            initial={{
              opacity: 0,
              translateY: "2rem",
            }}
            whileInView={{
              translateY: "0rem",
              opacity: 1,
              transition: {
                duration: 0.3,
              },
            }}
            animate="visible"
            viewport={{ once: true, amount: 0.5 }}
            className="font-extrabold text-[30px] md:text-[35px] md:w-max mb-7 md:mb-[3rem] leading-[35px] pr-16 md:pr-0 2x:pr-0"
          >
            Văzut din interior - Sesiune 1 la 1
          </motion.h1>
          <motion.p
            initial={{
              opacity: 0,
              translateY: "2rem",
            }}
            whileInView={{
              translateY: "0rem",
              opacity: 1,
              transition: {
                delay: 0.5,
                duration: 0.3,
              },
            }}
            animate="visible"
            viewport={{ once: true, amount: 0.5 }}
            className="md:w-full leading-[22px] md:leading-[40px] lg:leading-[28px] w-11/12 text-[18px] md:text-[20px] lg:text-[20px] text-[#727272] pb-[80px] md:pb-[7.188rem] half_border_bottom md:mb-0 mb-[45px]"
          >
            Dacă ești proprietarul / managerul unui service, acum ai
            posibilitatea să înveți cum să scalezi un service de la 0 la peste 1
            milion de euro în mai puțin de 5 ani începând cu o sesiune de
            consultanță 1 la 1, cu mine. Te voi învață să delegi sarcini și să
            ieși din operațional, astfel te vei putea bucura de rezultate.
          </motion.p>
        </div>
        <motion.div
          initial={{
            opacity: 0,
            translateY: "2rem",
          }}
          whileInView={{
            translateY: "0rem",
            opacity: 1,
            transition: {
              delay: 0.8,
              duration: 0.3,
            },
          }}
          animate="visible"
          viewport={{ once: true, amount: 0.5 }}
          className="flex-1 w-full flex justify-center md:block mb-[50px] md:mb-4 px-5 md:px-0 2xl:px-0"
        >
          <img
            className="md:animate-[wiggle_3s_ease-in-out_infinite] w-[26.563rem]"
            src={circleIcon}
            alt="circleIcon"
          />
        </motion.div>
      </div>

      <div className="h-auto flex justify-center pl-0  md:pl-20 mt-0 md:mt-40">
        <motion.div
          initial={{
            opacity: 0,
            translateY: "2rem",
          }}
          whileInView={{
            translateY: "0rem",
            opacity: 1,
            transition: {
              delay: 1,
              duration: 0.3,
            },
          }}
          animate="visible"
          viewport={{ once: true, amount: 0.5 }}
          className="md:mr-[10.5rem] mr-0 invisible md:visible"
        >
          <img
            className="hover:-rotate-2 transition duration-150 hover:duration-150 absolute md:relative invisible md:visible"
            src={verticalIcon}
            alt="verticalIcon"
          />
        </motion.div>
        <motion.div
          initial={{
            opacity: 0,
            translateY: "2rem",
          }}
          whileInView={{
            translateY: "0rem",
            opacity: 1,
            transition: {
              delay: 0.5,
              duration: 0.5,
            },
          }}
          animate="visible"
          viewport={{ once: true, amount: 0.3 }}
          className="h-full md:pl-0 pl-[15px] flex-1 pr-2 2xl:pr-80 md:pr-30 w-full pt-0 mb-[7.5rem]"
        >
          <h1 className="text-[16px] pr-[2px] md:pr-0  lg:text-[20px] lg:leading-[28px]  leading-[20px] md:w-full w-full text-[#707070]">
            <p className="text-[#171717]  font-medium w-full lg:w-8/12">
              Timp de 3 zile, voi fi prezent în service-ul tău, alături de tine
              și echipa ta și ne vom ocupa de:
            </p>
            <br />
            <ul className="listofText md:pr-0 leading-inherit ">
              <li className="md:mr-[10px] mr-0 ">
                Începerea unui sistem de management bazat pe responsabilități,
                procese și proceduri;
              </li>
              <li className="md:mr-[10px] mr-0">
                Implementarea unui flux de reparații bazat pe eficiență,
                calitate și transparenț
              </li>
              <li className="md:mr-[10px] mr-0">
                Stabilirea modelului de business;
              </li>
              <li className="md:mr-[10px] mr-0">
                Ședință cu echipa operațională (mecanici, consilieri service,
                etc.
              </li>
              <li className="md:mr-[10px] mr-0">
                Îți voi oferi răspuns pentru cele mai importante 5 întrebări din
                service-ul tau.
              </li>
            </ul>
            <br />
            <p className="md:w-11/12 w-full leading-[20px] lg:leading-[28px] 2xl:w-9/12 ">
              În cele 3 zile vei primi informații și resurse care te vor ajuta
              să delegi sarcini, să ai un service mai eficient și să câștigi mai
              mulți bani.
            </p>
            <br />
            <span className="text-[#707070] font-semibold pr-2 md:pr-0">
              {" "}
              Programează o întalnire online, GRATUITĂ cu un mail la
              consultanta@optimalgroup.ro.
            </span>
          </h1>
          <p className="text-[#D1914C] md:w-10/12 mt-6 md:mt-10 font-medium text-[16px] md:text-[20px] leading-[22px] lg:leading-[28px] ">
            Acum poți avea acces la resurse și informații adunate în peste 10
            ani de antreprenoriat în domeniul de servisare și nu numai!
          </p>
          <button className="customBtnBlack customBtn pBtn  mt-10 md:mt-16 font-semibold">
            <Link to="section5" smooth={true}>
              Vreau o schimbare acum!
            </Link>
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default Section4;
