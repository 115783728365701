
import "./module.css";
import ReactPlayer from "react-player";
import { BrowserView, MobileView } from "react-device-detect";

const Modal = ({ setIsOpen }) => {
  const videoLink = "https://www.youtube.com/watch?v=wk1hjnwx9lc";



  return (
    <>
      <div className="darkBG" onClick={() => setIsOpen(false)} />
      <div className="centered">
        <BrowserView>
          {" "}
          <ReactPlayer
            width="70vw"
            height="80vh"
            controls="true"
            playing="true"
            preload="auto"
            url={videoLink}
          />
        </BrowserView>
        <MobileView>
          {" "}
          <ReactPlayer
            width="50vw"
            height="50vh"
            controls="true"
            playing="true"
            preload="auto"
            url={videoLink}
          />
        </MobileView>
      </div>
    </>
  );
};

export default Modal;
