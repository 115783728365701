import React from "react";
import { motion } from "framer-motion";
// Utility Images
import utilImage1 from "../Icons/Utilitie_Icons/utilities0.svg";
import utilImage2 from "../Icons/Utilitie_Icons/utilities1.svg";
import utilImage3 from "../Icons/Utilitie_Icons/utilities2.svg";
import utilImage4 from "../Icons/Utilitie_Icons/utilities3.svg";
import utilImage5 from "../Icons/Utilitie_Icons/utilities4.svg";
import utilImage6 from "../Icons/Utilitie_Icons/utilities5.svg";

const Section3 = () => {
  return (
    <div className="h-max 2xl:h-[100vh] md:mb-[14rem] mt-5">
      <div className="2xl:h-[40%] md:h-[380px] h-[253px] bg-[#FFD45A] flex items-center flex-col justify-center">
        <motion.h1
          initial={{
            opacity: 0,
            translateY: "5rem",
          }}
          whileInView={{
            translateY: "0rem",
            opacity: 1,
            transition: {
              duration: 1,
            },
          }}
          animate="visible"
          viewport={{ once: true, amount: 1 }}
          className="font-extrabold text-[25px] md:text-[35px]  mb-2 md:pt-0 pt-[75px]"
        >
          Mentorul tău în business!
        </motion.h1>
        <motion.p
          initial={{
            opacity: 0,
            translateY: "5rem",
          }}
          whileInView={{
            translateY: "0rem",
            opacity: 1,
            transition: {
              delay: 0.5,
              duration: 1,
            },
          }}
          animate="visible"
          viewport={{ once: true, amount: 1 }}
          className=" text-[16px] md:text-[22px] lg:leading-[26px] leading-[18px]  w-11/12 md:w-6/12 text-center md:px-0 2xl:px-4 px-4  md:pb-0 pb-[75px]"
        >
          Acest program de creștere accelerată se adresează celor care au un
          service sau vor să deschidă unul care se ocupă cu:
        </motion.p>
      </div>
      <div className="md:mt-[131px] h-max ">
        <div className="sect3Flex flex flex-wrap items-center justify-center">
          <div className="md:basis-1/3 basis-1/2   ">
            <img
              src={utilImage1}
              alt="utilImage1"
              id="sect3Img1"
              className="hover:drop-shadow-5xl transition duration-150 hover:duration-150"
            />
            <p className="md:mb-[50px]">Reparații auto</p>
          </div>
          <div className="md:basis-1/3 basis-1/2 border_left md:border-r-2 md:border-[#e0e1e5]  md:border-solid border-r-0  md:pl-[20px] md:pr-[10px]">
            <img
              src={utilImage2}
              alt="utilImage2"
              id="sect3Img2"
              className="hover:drop-shadow-5xl transition duration-150 hover:duration-150 "
            />
            <p className="md:mb-[50px]">Reparații utilaje</p>
          </div>

          <div className="md:basis-1/3 basis-1/2 border_top md:border-t-0 md:mt-0  ">
            <img
              src={utilImage3}
              alt="utilImage3"
              id="sect3Img3"
              className=" hover:drop-shadow-5xl transition duration-150 hover:duration-150"
            />
            <p className="md:mb-[50px]">Reparații biciclete și trotinete</p>
          </div>
          <div className="md:basis-1/3 basis-1/2 border_top border_left md:border-l-0">
            <img
              src={utilImage4}
              alt="utilImage4"
              id="sect3Img4"
              className="md:mt-[65px] hover:drop-shadow-5xl transition duration-150 hover:duration-150"
            />
            <p className="">Reparații motociclete și ATV-uri</p>
          </div>
          <div className="md:basis-1/3 basis-1/2 border_top  md:border-l-2  border-l-0 md:border-r-2 md:border-[#e0e1e5]  md:border-solid border-r-0 ">
            <img
              src={utilImage5}
              alt="utilImage5"
              id="sect3Img5"
              className="md:mt-[65px] hover:drop-shadow-5xl transition duration-150 hover:duration-150"
            />
            <p className="">Tuning auto</p>
          </div>
          <div className="md:basis-1/3 basis-1/2 border_top border_left md:border-l-0 ">
            <img
              src={utilImage6}
              alt="utilImage6"
              id="sect3Img6"
              className="md:mt-[65px] hover:drop-shadow-5xl transition duration-150 hover:duration-150 "
            />
            <p className="">Recondiționări piese</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
