import React from "react";
import "./index.css";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Navbar and Top Section aka LandingPage
import LandingPage from "./components/landingPage";

// Sections
import Section1 from "./components/Section1.jsx";
import Section2 from "./components/Section2.jsx";
import Section3 from "./components/Section3.jsx";
import Section4 from "./components/Section4.jsx";
import Section5 from "./components/Section5.jsx";
import Footer from "./components/footer.jsx";
import Navbar from "./components/Navbar.jsx";

// Footer

// Context API

function App() {
  return (
    <>
      <Navbar className="fixed h-full" />
      <LandingPage />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Footer />
    </>
  );
}

export default App;
